<template>
    <div class="course-card-container">
        <div class="card-container">
            <div class="header">
                <div class="header-top">
                    <div class="img-holder">
                        <img :src="logo" alt="Icon" />
                    </div>

                    <div class="fav-compare-container">
                        <i
                            v-if="projectName === 'b2c'"
                            class="icomoon"
                            :class="[
                                favourite ? 'icon-favourite_active' : 'icon-favourite',
                                preventClick ? 'prevent-click' : '',
                            ]"
                            @click="checkFavourite"
                        ></i>

                        <span v-if="projectName === 'b2c' && compareActive && categoryType === 'university'">
                            <Checkbox v-model="checkedForCompare" binary @change="onCompareChange" />
                        </span>
                    </div>
                </div>

                <NuxtLink :to="finalLink" class="course-title-link">
                    <h3 class="course-title" :title="courseTitle">{{ courseTitle || '-' }}</h3>
                </NuxtLink>
                <div class="university-container">
                    <img
                        class="university-icon"
                        src="../../../assets/images/search/course_cap_2.svg"
                        alt="University Icon"
                    />
                    <NuxtLink :to="finalLink">
                        <h4 class="university-title" :title="categoryTitle">{{ categoryTitle || '-' }}</h4>
                    </NuxtLink>
                </div>
                <div class="date-container" v-if="date">
                    <img class="date-icon" src="../../../assets/images/search/calendar.svg" alt="Date Icon" />
                    <h4 class="date-label">{{ $t('Intakedate') }}</h4>
                    <span class="date" :title="date">{{ date }}</span>
                </div>
            </div>
            <hr class="h-line" />
            <div class="body-container">
                <div class="location-container">
                    <img class="location-icon" src="../../../assets/images/search/location.svg" alt="Location Icon" />
                    <h4 class="location-text" :title="location">{{ location || '-' }}</h4>
                </div>
                <div class="fees-container">
                    <img class="fees-icon" src="../../../assets/images/search/currency.svg" alt="Fees Icon" />
                    <h4 class="fees-label">{{ $t('fees') }}</h4>
                    <h4 :class="['fees', { 'blur-effect': onBoardingFlow && !isLoggedIn }]">
                        <template v-if="+fees"
                            >{{ formatNumberWithCommas(fees) || '-' }} {{ currency || '-' }}</template
                        >
                        <template v-else>{{ $t('askConsoler') }}</template>
                    </h4>
                    <span :class="{ 'blur-effect': onBoardingFlow && !isLoggedIn }">({{ $t('approximate') }})</span>
                </div>
            </div>
            <hr class="h-line" />
            <div class="footer">
                <span class="share-container" @click="copyLink">
                    <i class="icomoon icon-link"></i>
                </span>
                <CardButton class="action-btn" :text="btnText" :link="finalLink" />
            </div>
        </div>
    </div>
</template>

<script setup>
import CardButton from './CardButton.vue';
import { useToast } from 'primevue/usetoast';
import { useCommonStore } from '../../../store/search/common';
import { useCheckCategory } from '../../../composables/useCheckCategory';
import { formatNumberWithCommas } from '../../../utils/formatNumWithCommas';

const { $postApi } = useNuxtApp();
const commonStore = useCommonStore();
const { projectName, compareActive, compareList } = storeToRefs(commonStore);
const { getCategoryCourses } = useCheckCategory();
const toast = useToast();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();

//check if we are searching for courses from a student profile in B2B
const studentID = route.query.studentID ? route.query.studentID : null;

const checkedForCompare = ref(false);
const preventClick = ref(false);
const isLoggedIn = Boolean(useCookie('token').value);

const props = defineProps({
    id: {
        type: Number,
        default: '',
    },
    link: {
        type: String,
        default: '',
    },
    logo: {
        type: String,
        default: '',
    },
    courseTitle: {
        type: String,
        default: '',
    },
    categoryTitle: {
        type: String,
        default: '',
    },
    categoryLogo: {
        type: String,
        default: '',
    },
    level: {
        type: String,
        default: '',
    },
    requirements: {
        type: String,
        default: '',
    },
    categoryID: {
        type: String,
        default: '',
    },
    location: {
        type: String,
        default: '',
    },
    fees: {
        type: String,
        default: '',
    },
    currency: {
        type: String,
        default: '',
    },
    date: {
        type: String,
        default: '',
    },
    favourite: {
        type: Boolean,
        default: false,
    },
    btnText: {
        type: String,
        required: false,
        default: 'advSearch.exploreCourse',
    },
    category: {
        type: String,
        default: '',
    },
    onBoardingFlow: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const categoryType = computed(() => {
    if (props.category) return props.category;
    return route.path.includes('institute') ? 'institute' : 'university';
});

if (compareList.value.find(item => item.id === props.id)) {
    checkedForCompare.value = true;
}

const emit = defineEmits(['onToggleFavourite']);

const finalLink = computed(() => {
    if (props.onBoardingFlow && !isLoggedIn) return '/auth/signup?origin=on-boarding';
    return studentID !== null
        ? `/${categoryType.value}/course/${props.id}?studentID=${studentID}`
        : `/${categoryType.value}/course/${props.id}`;
});

const isChecked = computed(() => {
    return compareList.value.find(item => item.id === props.id);
});

const copyLink = async () => {
    if (props.onBoardingFlow && !isLoggedIn) {
        router.push('/auth/signup?origin=on-boarding');
        return;
    }
    let URL = location.origin;
    URL += categoryType.value === 'university' ? `/university/course/${props.id}` : `/institute/course/${props.id}`;
    try {
        await navigator.clipboard.writeText(URL);
        toast.add({
            severity: 'success',
            summary: t('toast_success'),
            detail: t('copydone'),
            life: 2000,
        });
    } catch (err) {}
};

// Switch favourite state of course
const checkFavourite = async () => {
    if (!isLoggedIn) {
        router.push('/auth/login');
        return;
    }

    let dataSend = new FormData();

    categoryType.value === 'university'
        ? dataSend.append('university_course_id', props.id)
        : dataSend.append('english_course_id', props.id);

    if (!props.favourite) dataSend.append('favourite', true);

    try {
        preventClick.value = true;
        const response = await $postApi('favourite', dataSend);
        if (response?.status !== 'success') {
            throw new Error(t('failedToUpdateFavourite'));
        }
        toast.add({
            severity: 'success',
            summary: t('toast_success'),
            detail: props.favourite ? t('removedDone') : t('addedDone'),
            life: 2000,
        });
        getCategoryCourses.value();
        emit('onToggleFavourite', !props.favourite);
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: t('toast_error'),
            detail: error?.message,
            life: 2000,
        });
    } finally {
        preventClick.value = false;
    }
};

const onCompareChange = () => {
    if (checkedForCompare.value) {
        if (compareList.value.length < 3) {
            compareList.value.push({
                id: props.id,
                name: props.courseTitle,
                location: props.location,
                categoryTitle: props.categoryTitle,
                categoryLogo: props.categoryLogo,
                fees: formatNumberWithCommas(props.fees),
                currency: props.currency,
                intakeDate: props.date,
                level: props.level,
                requirements: props.requirements,
            });
        } else {
            checkedForCompare.value = false;
            toast.add({
                severity: 'error',
                summary: t('toast_error'),
                detail: t('compare3'),
                life: 2000,
            });
        }
    } else {
        compareList.value = compareList.value.filter(item => item.id !== props.id);
    }
};
watch(isChecked, newValue => {
    checkedForCompare.value = Boolean(newValue?.id);
});
</script>

<style lang="scss" scoped>
.blur-effect {
    filter: blur(3px);
    filter: unset !important;
}
@import 'libs/shared/assets/scss/main.scss';
.course-card-container {
    padding: toRem(16) toRem(20);
    border-radius: toRem(10);
    border: 1px solid $lighter-gray;
    box-shadow: 0px 4px 8px 0px #0000001a;
    height: 100%;
    .card-container {
        display: flex;
        gap: toRem(16);
        flex-direction: column;
        height: 100%;
        .header {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            .header-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: toRem(16);
                .img-holder {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid $gray-4;
                    border-radius: toRem(4);
                    width: toRem(50);
                    height: toRem(50);
                    img {
                        width: toRem(40);
                    }
                }
                i {
                    font-size: toRem(20);
                    color: $primary-color;
                    cursor: pointer;
                    &.icon-favourite_active {
                        color: $red-1;
                    }
                    &.prevent-click {
                        pointer-events: none;
                    }
                }

                .fav-compare-container {
                    display: flex;
                    gap: toRem(8);
                    align-items: center;
                    justify-content: center;
                }
            }
            .course-title-link {
                flex-grow: 1;
                .course-title {
                    font-size: toRem(18);
                    font-weight: 600;
                    color: $primary-color;
                    @include lines(2);
                    margin-bottom: toRem(6);
                    cursor: pointer;
                }
            }
            .university-container {
                display: flex;
                align-items: center;
                gap: toRem(8);
                .university-icon {
                    width: toRem(16);
                }
                .university-title {
                    font-size: toRem(14);
                    font-weight: 400;
                    text-decoration: underline;
                    color: $primary-color;
                    cursor: pointer;
                    @include lines(1);
                }
            }
            .date-container {
                display: flex;
                align-items: center;
                gap: toRem(8);
                margin-top: toRem(8);
                .date-icon {
                    width: toRem(12);
                }
                .date-label {
                    font-size: toRem(12);
                    color: $gray;
                    font-weight: 400;
                    white-space: nowrap;
                }
                .date {
                    font-size: toRem(12);
                    color: black;
                    font-weight: 600;
                    @include lines(1);
                }
            }
        }
        .body-container {
            .location-container {
                align-items: center;
                display: flex;
                gap: toRem(9);
                margin-bottom: toRem(8);
                .location-icon {
                    width: toRem(11);
                }
                .location-text {
                    font-weight: 400;
                    font-size: toRem(12);
                    color: $dim-gray;
                    @include lines(1);
                }
            }
            .fees-container {
                align-items: center;
                display: flex;
                gap: toRem(9);
                .fees-icon {
                    width: toRem(16);
                }
                .fees-label {
                    font-size: toRem(12);
                    color: $gray;
                    font-weight: 400;
                }
                .fees {
                    font-size: toRem(12);
                    color: black;
                    font-weight: 600;
                }
                span {
                    font-size: toRem(9);
                    color: $gray-7;
                }
            }
        }
        .footer {
            display: flex;
            width: 100%;
            gap: toRem(8);
            .share-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: toRem(48);
                height: toRem(48);
                background-color: $lavender-3;
                border-radius: toRem(10);
                cursor: pointer;
                i {
                    color: $primary-color;
                    font-size: toRem(16);
                }
            }
            .action-btn {
                width: calc(100% - 48px);
            }
        }
        .h-line {
            border-color: $gray-6;
            opacity: 1;
            margin: 0;
        }
    }
}
</style>

